.contentCloseAN
    display: flex
    flex-direction: row
    align-items: center
    padding: 20px 20px
    margin: -20px -14px 0
    box-sizing: border-box
    width: calc(100% + 30px)
    border-bottom: 1px solid var(--color-gris-borde)
    position: sticky
    top: -20px
    background: white
    z-index: 1
    box-shadow: -2px 8px 13px rgba(0, 0, 0, 0.15)
    .is-dark-mode &
        background-color: var(--color-background-dm)
        position: sticky !important
    p
        width: calc(100% - 60px)
        font-weight: 600
    .btn
        box-sizing: border-box
        display: flex
        flex-direction: row
        align-items: center
        justify-content: center
        border-radius: 5px
        margin-bottom: 10px
        font-size: 14px
        font-weight: 700
        text-decoration: none
        padding: 8px 16px
        cursor: pointer
        width: 100%
        height: 42px
        -webkit-user-select: none
        user-select: none
        .is-dark-mode &
            color: white
            filter: invert(1)

    .btn.closeAN
        position: relative
        display: inline
        top: 3px
        right: inherit
        margin-bottom: 0
        margin-right: 20px
        background-color: white
        color: black
        transform: rotate(180deg)
        cursor: pointer
        font-weight: 500
        width: 25px
        padding: 5px
        height: 25px
        text-align: center
        line-height: 0.9
        .is-dark-mode &
            background-color: transparent
            color: white
        &:before
            content: ''//'\2716'
            background-image: url(/img/closeCirculo.png)
            font-size: 20px
            width: 30px
            height: 30px
            background-repeat: no-repeat
            background-position: 50%
            background-size: 100%
            position: relative
            // left: 3px
            // top: 3px
            display: block
            .is-dark-mode &
                color: white
                filter: invert(1)
    .btn.closeBack
        position: relative
        display: inline
        // top: 3px
        right: inherit
        margin-bottom: 0
        margin-right: 20px
        background-color: white
        color: black
        // transform: rotate(180deg)
        cursor: pointer
        font-weight: 500
        width: 25px
        padding: 5px
        height: 25px
        text-align: center
        line-height: 0.9
        .is-dark-mode &
            background-color: transparent
            color: white
        &:before
            // content: ""
            // // right: 20px
            // // top: 50%
            // width: 10px
            // height: 10px
            // display: block
            // border-top: 2px solid
            // border-right: 2px solid
            // transform: rotate(45deg)
            // top: 3px
            // position: relative
            content: ''
            position: absolute
            transform: rotate(180deg)
            bottom: -4px
            content: ''
            background-image: url(/img/group-9.png)
            font-size: 20px
            width: 50px
            height: 35px
            background-repeat: no-repeat
            background-position: 50%
            background-size: 70%
            right: -12px
            .is-dark-mode &
                color: white
                filter: invert(1)

.contentCloseAN2
    display: flex
    flex-direction: row-reverse
    align-items: center
    padding: 20px 10px
    margin: -20px -14px 0
    box-sizing: border-box
    width: calc(100% + 30px)
    border-bottom: 1px solid var(--color-gris-borde)
    position: sticky
    top: -20px
    background: white
    z-index: 1
    box-shadow: -2px 8px 13px rgba(0, 0, 0, 0.15)
    .is-dark-mode &
        background-color: var(--color-background-dm)
        position: sticky !important
    p
        width: calc(100% - 60px)
        font-weight: 600
        color: #4A4A4A
        .is-dark-mode &
            color: #ededed
    .btn
        box-sizing: border-box
        display: flex
        flex-direction: row
        align-items: center
        justify-content: center
        border-radius: 5px
        margin-bottom: 10px
        font-size: 14px
        font-weight: 700
        text-decoration: none
        padding: 8px 16px
        cursor: pointer
        width: 100%
        height: 42px
        -webkit-user-select: none
        user-select: none
        .is-dark-mode &
            color: white
            filter: invert(1)

    .btn.closeAN2
        position: relative
        display: inline
        top: 3px
        right: inherit
        margin-bottom: 0
        margin-right: 20px
        background-color: white
        color: black
        transform: rotate(180deg)
        cursor: pointer
        font-weight: 500
        width: 25px
        padding: 5px
        height: 25px
        text-align: center
        line-height: 0.9
        .is-dark-mode &
            background-color: transparent
            color: white
        &:before
            content: ''//'\2716'
            background-image: url(/img/closeCirculo.png)
            font-size: 20px
            width: 30px
            height: 30px
            background-repeat: no-repeat
            background-position: 50%
            background-size: 100%
            position: relative
            // left: 3px
            // top: 3px
            display: block
            // content: '\2716'
            // font-size: 20px
            .is-dark-mode &
                color: white
                filter: invert(1)
    .btn.closeBack
        position: relative
        display: inline
        top: 3px
        right: inherit
        margin-bottom: 0
        margin-right: 20px
        background-color: white
        color: black
        transform: rotate(180deg)
        cursor: pointer
        font-weight: 500
        width: 25px
        padding: 5px
        height: 25px
        text-align: center
        line-height: 0.9
        .is-dark-mode &
            background-color: transparent
            color: white
        &:before
            // content: ""
            // // right: 20px
            // // top: 50%
            // width: 10px
            // height: 10px
            // display: block
            // border-top: 2px solid
            // border-right: 2px solid
            // transform: rotate(45deg)
            // top: 3px
            // position: relative
            content: ''
            position: absolute
            // transform: rotate(180deg)
            bottom: 13px
            content: ''
            background-image: url(/img/group-9.png)
            font-size: 20px
            width: 30px
            height: 30px
            background-repeat: no-repeat
            background-position: 50%
            background-size: 100%
            .is-dark-mode &
                color: white
                filter: invert(1)

.button.rec-dot:hover, button.rec-dot:active, button.rec-dot:focus,
.sc-hBMUJo.kvsGio.rec.rec-dot.rec.rec-dot_active,
.kvsGio:hover,
.kvsGio:focus,
.rec-dot_active
    box-shadow: 0 0 1px 3px rgba(0,0,0, 0.5) !important
    background-color: #e4002b !important
.ligdpT
    .is-dark-mode &
        box-shadow: 0 0 1px 2px rgb(255, 255, 255) !important
.padding10PF
    padding: 10px !important
.paddingTop
    padding-top: 20px
.size12px
    font-size: 12px
.size14px
    font-size: 14px
.bxPF
    background-color: #ededed
    padding: 20px 15px
    text-align: left
    .is-dark-mode &
        background-color: #555
    p
        .is-dark-mode &
            color: #ededed
    .colorRed
        color: #e4002b
.boxAN
    .bxSelects
        .fileset1
            select
                background-color: transparent !important
                outline: none !important
                background-size: 15px
    .accordion
        border: none
        .accordion-item
            border-bottom: 1px solid #ededed
            .is-dark-mode &
                border-bottom: 2px solid #313131
            .panel
                padding-bottom: 15//20px
                width: 90%
                margin-left: auto
                margin-right: auto
            &.active
                .panel
                    padding-bottom: 15px//20px
                    width: 90%
                    margin-left: auto
                    margin-right: auto
                .title::after
                    content: "\025ac"//"\2212"
                    border: 2px solid #cacaca
                    border-radius: 50%
                    color: #cacaca
                    padding: 3px 5px

            .title
                background-color: transparent
                font-weight: 600
                display: flex
                align-items: center
                justify-content: space-between
                .is-dark-mode &
                    color: #ededed
                &::after
                    border: 2px solid #e4002b
                    padding: 2px 5px
                    display: flex
                    content: "\0271a"
                    border-radius: 50%
                    font-weight: 700
                    color: #e4002b

    .bxSlider
        height: 100%
        .boxSliderAutocenter
            min-height: 250px
            max-height: 400px
        .formContact
            margin-top: 15px
            width: 90%
            margin-left: auto
            margin-right: auto
        .colorRed
            color: #e4002b !important
        .btnEnviarMensaje
            width: 70%
            margin-left: auto
            margin-right: auto
            padding-bottom: 20px
            padding-top: 20px
            button
                cursor: pointer
                -o-transition: all .3s
                -ms-transition: all .3s
                transition: all .3s
                width: 100%
                border: none
                background: linear-gradient(0deg, #e4002b, #f30056)
                text-decoration: none
                padding: 8px 16px
                color: #fff!important
                font-weight: 300!important
                border-radius: 50px!important
                font-size: 13px!important
                text-align: center!important
                font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif!important
        .barraAgendar
            padding: 10px 20px
            box-sizing: border-box
            text-align: left
            position: relative
            cursor: pointer
            overflow: hidden
            margin-bottom: 10px
            margin-top: -1px
            font-size: 16px
            color: #4a4a4a
            background: #fff
            font-weight: 600
            &::after
                content: ""
                position: absolute
                right: 20px
                top: 50%
                margin-top: -6px
                width: 10px
                height: 10px
                display: block
                border-top: 2px solid
                border-right: 2px solid
                transform: rotate(45deg)
                color: #4a4a4a
        .ulServMo
            .bxTitleSerMo
                // background-color: black
                // color: #fff
                text-align: left
                .is-dark-mode &
                    // background-color: #333
                p
                    padding: 10px
                    font-weight: 600
                    .is-dark-mode &
                        color: #ededed
            .internoServMo
                width: 96%
                margin-left: auto
                margin-right: auto
                display: grid
                grid-gap: 10px
                gap: 10px
                padding-top: 10px
                padding-bottom: 10px
                grid-template-columns: repeat(2, 1fr)
                grid-template-rows: repeat(1, 1fr)
                border-bottom: 1px solid #ededed
                margin-bottom: 10px
                @media only screen and (max-width:361)
                    grid-template-columns: repeat(1, 1fr)
                .is-dark-mode &
                    border-bottom: 2px solid #242424
                .liServMo
                    display: flex
                    align-items: center
                    text-align: left
                    width: 98%
                    .is-dark-mode &
                        color: #ededed
                    .spanLi
                        width: calc( 100% - 45px)
                        .desktopView &
                            width: calc( 100% - 80px)
                            text-align: center
                    span
                        padding-left: 5px
                        font-weight: 500
                    .imgLi
                        width: 42px
                        .desktopView &
                            width: 60px
                    img
                        max-width: 42px
                        .desktopView &
                            max-width: 60px
                            height: auto
        .sliTop
            display: flex
            align-items: center
            padding-top: 120px
            .mvv
                .is-dark-mode &
                    filter: invert(1)
        .pSlider
            padding-top: 15px
            text-align: center
            line-height: 1.3
            color: #4A4A4A
            .is-dark-mode &
                color: #b7b7b7
        .grisClaro
            color: rgb(148, 148, 148) !important
            font-size: 14px
            line-height: 18px !important
        .pSliderL
            padding-top: 18px
            text-align: left
            line-height: 1.3
            color: #4A4A4A
            .is-dark-mode &
                color: #b7b7b7
        .colorRed
            color: #e4002b
        .colorNegro
            color: #000 !important
        .boldSlider
            font-weight: 600
        .pSliderTitle
            font-weight: 600
            font-size: 18px
            text-align: center
            padding-top: 20px
        .pSliderTitleL
            font-weight: 600
            font-size: 18px
            text-align: left
            padding-top: 20px
            .is-dark-mode &
                color: #b7b7b7
        .pSliderTitleLred
            font-weight: 600
            font-size: 18px
            text-align: left
            padding-top: 20px
            color: #e4002b
            .is-dark-mode &
                color: #e4002b
        .bold
            font-weight: bold
        .pSliderTitleul
            font-weight: 600
            font-size: 18px
            text-align: center
            padding-top: 20px
            padding-bottom: 15px
        .pSliderli
            text-align: center
            padding-top: 5px
            color: #4A4A4A
            .is-dark-mode &
                color: #b7b7b7
        a
            text-decoration: none
            color: var(--color-azul)
        .bxDeclaraciones
            h2
                font-weight: 600
                padding-top: 10px

            .h3
                font-weight: 600
                padding-top: 10px

            p
                line-height: 1.3
            .ulDeclaracionesRomano
                list-style: upper-roman
                width: 93%
                margin-left: auto
                margin-right: auto
                padding-top: 10px
                padding-bottom: 10px
                li
                    line-height: 1.3
                    color: #4A4A4A
                    .is-dark-mode &
                        color: #b7b7b7
        .pbottom
            padding-bottom: 15px
        .ulDeclaracionesNone
            list-style: none
            width: 98%
            margin-left: auto
            margin-right: auto
            padding-top: 10px
            padding-bottom: 10px
            li
                padding-top: 5px
                line-height: 1.3
                color: #4A4A4A
                .is-dark-mode &
                    color: #b7b7b7
        .ulW85
            width: 85% !important
        .ulDeclaracionesDisc
            list-style: disc
            width: 93%
            margin-left: auto
            margin-right: auto
            padding-top: 10px
            padding-bottom: 10px
            li
                padding-top: 5px
                line-height: 1.3
                color: #4A4A4A
                .is-dark-mode &
                    color: #b7b7b7
        .ulDeclaracionesNumber
            list-style: decimal
            width: 91%
            margin-left: auto
            margin-right: auto
            padding-top: 10px
            padding-bottom: 10px
            li
                padding-top: 5px
                line-height: 1.3
                color: #4A4A4A
                .is-dark-mode &
                    color: #b7b7b7
        .ulDeclaracionesDiscAccordion
            list-style: disc
            width: 91%
            margin-left: auto
            margin-right: auto
            padding-top: 10px
            padding-bottom: 10px
            li
                padding-top: 5px
                line-height: 1.3
                color: #4A4A4A
                .is-dark-mode &
                    color: #b7b7b7
        .h3
            font-weight: 600
            color: #4A4A4A
            padding-top: 10px
            padding-bottom: 5px
            .is-dark-mode &
                color: #b7b7b7
        .ulDeclaraciones
            list-style: none
            width: 91%
            margin-left: auto
            margin-right: auto
            padding-top: 10px
            padding-bottom: 10px
            li
                padding-top: 5px
                line-height: 1.3
                color: #4A4A4A
                .is-dark-mode &
                    color: #b7b7b7
        .bxPaypal
            display: flex
            justify-content: center
            align-items: center
            img
                width: 30px
                height: auto
            p
                width: calc( 100% - 45px )
                margin-left: auto
                margin-right: auto
        .bxDudasC
            display: flex
            align-items: center
            margin-left: 6%//20px
            .bxD1fijo2
                min-width: 45px
                padding-top: 20px
                margin-right: 5px
            .bxD1fijo
                min-width: 45px
                padding-top: 20px
                margin-right: 5px
            .bxD12
                padding-top: 20px
                min-width: 45px
                margin-right: 5px
                a
                    img
                        width: 35px
                        height: auto
                .azulL
                    color: rgb(37, 145, 180)
                    cursor: pointer !important
            .bxD1
                padding-top: 20px
                width: 45px//15%
                margin-right: 5px
                a
                    img
                        width: 35px
                        height: auto
                .azulL
                    color: rgb(37, 145, 180)
                    cursor: pointer !important
            .bxD2
                padding-top: 20px
                .pt
                    margin-top: 4px
                .pd
                    color: #4A4A4A
                    margin-bottom: 4px
                    .is-dark-mode &
                        color: #ededed
                .azulL
                    color: rgb(37, 145, 180)
                    cursor: pointer !important
.contentModalPadd
    padding: 20px 0px !important
.contentC
    padding: 20px 30px !important

.barraInferiorDudas
    width: 100%
    // border-bottom: 1px solid var(--color-gris-borde)
    background: white
    top: -5px
    position: relative
    .is-dark-mode &
        background: transparent
    .barraDudas
        box-sizing: border-box
        display: flex
        align-items: baseline
        justify-content: center
        margin-top: 15px
        margin-bottom: 15px
        margin-left: auto
        margin-right: auto
        width: 99%
        background: #ededed
        border-radius: 20px
        .is-dark-mode &
            background: #b7b7b7
        .activaSeccionLocalizador
            cursor: pointer
            color: white
            background: linear-gradient(0deg, #e4002b, #f30056)
            padding: 5px 5px
            border-radius: 20px
            width: 50%
            box-shadow: -2px 3px 3px rgba(0, 0, 0, 0.30)
        .activaSeccionDudas
            cursor: pointer
            color: white
            background: linear-gradient(0deg, #e4002b, #f30056)
            padding: 5px 5px
            border-radius: 20px
            width: 50%
            box-shadow: -2px 3px 3px rgba(0, 0, 0, 0.30)
        .noActivaDudas
            border: none
            cursor: pointer
            background: #ededed
            border-radius: 20px
            padding: 5px
            color: #e4002b
            width: 50%
            .is-dark-mode &
                background: #b7b7b7
        .p2Dudas
            display: flex
            position: relative
            justify-content: center
            font-size: 13px
        .p1Dudas
            text-align: center
            font-size: 13px
