@import '../../../sass/_abstracts'

.contProductCategoryDesk
    display: flex
    width: 100%
    margin-bottom: 20px
    +mediaMax780
        display: block
    .boxFiltrosDesk
        width: 100%
        max-width: 245px//300px
        +mediaMax780
            max-width: 96%
            margin-left: auto
            margin-right: auto
        .is-dark-mode &
            color: var(--color-text-placeholder-dm)
            box-sizing: border-box
    .boxGeneralProductosResultados
        width: calc(100% - 260px)
        padding-left: 20px
        box-sizing: border-box
        +mediaMax780
            width: 96%
            padding-left: 0
            margin-left: auto
            margin-right: auto
        .boxProductosCategory
            width: 100%
            flex-direction: row
            display: flex
            flex-wrap: wrap
        .bxTituloProductos
            width: 100%
            +mediaMax780
                margin-bottom: 10px
            p
                font-weight: 700
                text-align: left
                padding-left: 15px
                font-size: 18px
                padding-bottom: 3px
            .bxinteriorproductos
                border: 1px solid #e4002b
                width: 30%
                margin-left: 28px
    .boxGeneralSinResultados, .boxSinResultados
        width: 100%
        padding-left: 20px
        box-sizing: border-box
        .boxProductosCategory
            width: 100%
            flex-direction: row
            display: flex
            flex-wrap: wrap

.secTitleSearch
    display: flex
    +mediaMax780
        display: block
    .search1
        width: 245px
        +mediaMax780
            display: none
    .search2
        width: calc( 100% - 245px )
        padding-left: 25px !important
        +mediaMax780
            width: 100%
            padding-left: 0 !important
.titleSearchWork
    text-align: left
    padding: 20px 0
    font-size: 18px
    font-weight: 500
    color: #e4002b
    .is-dark-mode &
        color: white
    @media only screen and (max-width: 780px)
        padding: 0 0 20px
    @media only screen and (max-width: 760px)
        padding: 0 20px 20px
    span
        font-weight: 700
.titleSearchWorkMovil
    text-align: left
    font-size: 16px
    font-weight: 600
    padding-bottom: 10px
    padding-top: 10px
    width: 100%
    .is-dark-mode &
        color: white

.templateSearchResult,
.boxGeneralProductosResultados
    .cardGrid
        .cardProduct
            .contDataCard
                .h5
                    display: none

.boxSuggestion
    width: 100%
    text-align: left
    margin-top: 20px
    padding-bottom: 10px
    border-bottom: 1px solid var(--color-gris-borde)
    margin-bottom: 10px
    .is-dark-mode &
        color: white
    a
        color: #009966
        font-weight: 600
        text-decoration: none
    .opcion2
        font-weight: 600
.boxSuggestionMovil
    text-align: left
    font-weight: 500
    border-bottom: 1px solid var(--color-gris-borde)
    margin-top: 10px
    padding-bottom: 10px
    .is-dark-mode &
        color: white
    a
        color: #009966
        font-weight: 600
        text-decoration: none
        .opcion2
            font-style: italic
