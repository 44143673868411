.boxModuleGeneral
    display: inline-block
    height: auto
    min-height: 218px
    padding: 10px 20px 30px!important
    text-align: left
    vertical-align: top!important
    border-right: 1px solid #ddd!important
    &:last-child
        border-right: none!important
    @media only screen and (max-width: 960px)
        box-sizing: border-box
        padding: 10px 40px 30px
        width: 49%
        text-align: center
        &:nth-child(even)
            border-right: none!important
    @media only screen and (max-width: 480px)
        height: auto
        min-height: auto
        padding: 10px 40px
        width: 100%
        border-right: none!important
    > p,
    > a
        font-weight: 700
        color: #333
        font-size: 0.9rem
        padding: 16px 0
        display: block
        @media only screen and (max-width: 480px)
            background: var(--color-gris-borde)
            text-align: center
            border-radius: 5px
            cursor: pointer
            &.active
                + ul
                    transition: all .3s
                    max-height: 100%
                    overflow: hidden
    > a
        cursor: pointer
        text-decoration: none

    ul
        @media only screen and (max-width: 960px)
            width: auto
        @media only screen and (max-width: 480px)
            transition: all .3s
            max-height: 0
            overflow: hidden
    li
        color: #555
        font-size: 0.8rem
        padding: 7px 0
        a
            color: #555
            font-size: 0.8rem
            padding: 7px 2px 7px 0
            text-decoration: none
    p
        margin: 0
    ul
        list-style: none

.paragraph
    display: inline-block
    font-size: 0.7rem
    color: #555
    font-weight: 400
    P
        display: inline-block
        font-size: 0.7rem
        color: #555
        font-weight: 400
        padding: 0
        margin-bottom: 5px
    

.redesLogos
    margin-bottom: 10px
    display: flex
    flex-direction: column
    ul
        display: inline-block
        overflow: visible !important
        margin-top: 10px
    li
        transition: all 0.3s
        display: inline-block
        width: 15px
        height: 15px
        background: #aaa
        padding: 5px !important
        margin: 2px
        border-radius: 5px
        color: #555 !important
        font-size: 0.8rem!important
        &:hover
            opacity: 0.5
        a
            color: #555
            font-size: 0.8rem
            padding: 7px 2px 7px 0
            text-decoration: none

    .textFollow
        display: inline-block
        font-size: 0.7rem
        color: #555

    img
        border: none

    *, 
    *::before, 
    *::after
        box-sizing: content-box

    p
        margin: 0
    ul
        list-style: none