@import '../../../../../sass/_abstracts'

.marginAD
    margin-left: auto !important
    margin-right: auto !important
    border-left: none !important
.marginT30
    margin-top: 30px !important
.marginT60
    margin-top: 60px !important
.marginB40
    margin-bottom: 40px !important
.textCenter
    text-align: center !important
.textLeft
    text-align: left !important
.borderNone
    border: none !important
.fontRed
    color: var(--color-rojo-pale)
.bold
    font-weight: 600
.marginT5
    margin-top: 5px !important
.paddingLeft10
    padding-left: 15px !important
.borderGray
    border: 1px solid rgba(0,0,0,0.2)
    border-radius: 8px
    padding: 15px
.styleP
    margin-top: 10px
    line-height: 20px
.fontSize22
    font-size: 22px !important
.colorGray
    color: rgb(74,74,74) !important

.contenedorTYPicon
    display: flex
    align-items: center
    .icono
        content: ''
        background-image: url(/img/enviadoAD.svg)
        background-repeat: no-repeat
        width: 29px
        height: 20px
        max-width: 29px !important
        margin-right: 5px
        min-width: 29px
.miCuentaContainer
    margin-bottom: 50px
    .containerAD
        display: flex
        width: 100%
        max-width: 92% !important
        padding: 20px 20px 0 !important
        margin: auto
        +mediaMax780
            display: block
            max-width: 96%
            padding: 0 !important
        .menuMiCuentaAD
            width: 100%
            max-width: 300px
            min-width: 200px
            height: 100%
            border-radius: 10px
            margin-top: 15px
            +mediaMax780
                max-width: 100%
                margin-bottom: 20px
        .contenidoGeneralAD
            box-sizing: border-box
            position: relative
            width: 100%
            max-width: 99%
            height: auto
            margin: 20px 20px
            padding: 0
            border: 1px solid #eaeaea
            +mediaMax780
                margin: 50px 0 70px 0 !important

        .boxBtnRegresarDktopRojo
            display: flex
            flex-direction: row
            align-items: center
            padding: 12px 0px
            box-sizing: border-box
            position: sticky
            background: var(--color-rojo-pale)
            z-index: 1
            justify-content: space-around
            color: #fff
            border-bottom: 1px solid var(--color-rojo-pale)
            .btnBackADLogo
                border: none
                outline: none
                cursor: pointer
                background-image: url(/img/logoSEblanco.svg)
                background-repeat: no-repeat
                width: 130px
                height: 30px
                background-color: transparent
            .icoBackClose
                position: relative
                display: flex
                right: inherit
                margin-bottom: 0
                margin-left: 10px
                background-color: transparent
                color: #fff
                transform: rotate(180deg)
                cursor: pointer
                width: 28px
                padding: 5px
                height: 28px
                text-align: center
                line-height: 0.9
                left: 0
                position: absolute
                border-radius: 50%
                justify-content: center
                align-items: center
                .is-dark-mode &
                    color: white
                    background-color: transparent
                &::before
                    content: '\2716'
                    font-size: 18px
                    font-weight: 400
        .boxBtnRegresarDktop
            margin-bottom: 0px
            margin-top: 5px
            display: flex
            flex-direction: row
            align-items: center
            padding: 8px 0px
            box-sizing: border-box
            position: sticky
            background: #fff
            z-index: 1
            justify-content: space-around
            color: #4a4a4a
            border-bottom: 1px solid #e3e3e3
            .btnBackAD
                background: none
                border: none
                outline: none
                font-size: 16px
                cursor: pointer
                .is-dark-mode &
                    color: white
            .icoBack
                position: relative
                display: flex
                right: inherit
                margin-bottom: 0
                margin-left: 10px
                background-color: #EB0028
                color: #fff
                transform: rotate(180deg)
                cursor: pointer
                width: 25px
                padding: 5px
                height: 25px
                text-align: center
                line-height: 0.9
                left: 0
                position: absolute
                border-radius: 50%
                justify-content: center
                align-items: center
                .is-dark-mode &
                    color: white
                    background-color: transparent
                &::before
                    content: '\2794'
                    font-size: 18px
        .boxGeneralAD
            width: 100%
            display: block
            margin-left: auto
            margin-right: auto
            .boxLeftAD
                width: 100%
                .contSectionRed
                    background: #eee
                    color: #4a4a4a
                    -webkit-box-sizing: border-box
                    box-sizing: border-box
                    display: -webkit-box
                    display: -ms-flexbox
                    webkit-display: -webkit-flex
                    display: flex
                    flex-direction: row
                    -webkit-box-direction: row
                    -ms-box-direction: row
                    -ms-flex-direction: row
                    -webkit-box-align: center
                    align-items: center
                    -webkit-align-items: center
                    -ms-flex-align: center
                    padding: 20px
                    justify-content: center
                    .imaDesktop
                        display: -webkit-box
                        display: -ms-flexbox
                        webkit-display: -webkit-flex
                        display: flex
                        flex-direction: row
                        -webkit-box-direction: row
                        -ms-box-direction: row
                        -ms-flex-direction: row
                        flex-wrap: wrap
                        -webkit-box-wrap: wrap
                        -webkit-flex-wrap: wrap
                        -ms-flex-wrap: wrap
                        width: 80px
                        height: 80px
                        border-radius: 50%
                        max-width: 100%
                        min-width: 70px
                        min-height: 70px
                        background: white
                        overflow: hidden
                        border: 2px solid #e4e4e4
                        margin-right: 10px
                    .imaDesktop.more
                        img
                            width: calc(50% - 1px)
                            max-height: 35px
                            height: auto
                            border-right: 1px solid var(--color-gris-borde)
                            border-bottom: 1px solid var(--color-gris-borde)
                    .imaDesktop
                        img
                            width: 100%
                            height: auto

                    .textDektop
                        font-weight: 500
                        font-size: 16px
                        max-width: 350px
                        text-align: left
            .boxRightAD
                width: 100%
                min-height: 400px
                height: 100%
                .is-dark-mode &
                    border-left: 1px solid #484848
                +mediaMax780
                    width: 90%
                    margin-left: auto
                    margin-right: auto
                    border-left: none !important

                .copyEspera
                    background: none
                    margin-top: 0 !important
                .boxSolicitarDevolucion
                    width: 96%
                .boxCancelacionExitosa
                    .boxContenido
                        width: 90%
                        margin-left: auto
                        margin-right: auto
                        +mediaMax780
                            margin-top: 50px !important
                            width: 100%
                    .boxBtnRegresar
                        +mediaMax780
                            width: 50%
                .copyFormAD
                    width: 60%
                    margin: 20px auto 30px auto
                    p
                        text-align: left
                        font-weight: 500
                .formInformacionBancaria
                    display: grid
                    width: 60%
                    margin: auto auto 30px auto
                    +mediaMax780
                        width: 100%
                        margin-left: 0
                    .fileset
                        margin-bottom: 30px
                    .fileset1
                        margin-bottom: 30px
                        margin-top: 15px
                    .desktInput
                        text-align: left
                    .selectAD
                        border: 1px solid var(--color-gris-borde)
                        border-radius: 5px
                        background-position: 98% center
                    .bottomDesktop
                        margin-top: 10px
                .normasL1
                    text-align: left
                .boxGeneralAdjuntar
                    width: 90%
                    margin-left: auto
                    margin-right: auto
                    margin-top: 30px
                    +mediaMax580
                        width: 100%
                    .copy1,
                    .copy2
                        text-align: left
                        margin-left: 20px
                    .copyGris
                        color: #aaa
                    .adjuntarLR
                        display: flex
                        align-items: center
                        margin-bottom: 40px
                        align-items: center
                        +mediaMax780
                            display: block
                        .leftimagen,
                        .rightimagen
                            width: 50%
                            +mediaMax780
                                width: 100%
                            .contenedorIma
                                .tituloIma
                                    font-weight: 500
                                    margin-bottom: 10px
                                    text-align: left
                                    margin-left: 20px
                                .contenidoIma
                                    grid-template-columns: repeat(2, 1fr)
                                    display: grid
                                    margin-bottom: 40px
                                    align-items: center
                                    .imagen
                                        overflow: hidden
                                        img
                                            max-width: 110px
                                            @media only screen and ( max-width: 1000px )
                                                max-width: 80px
                                            @media only screen and ( max-width: 780px )
                                                max-width: 110px
                                            @media only screen and ( max-width: 380px )
                                                max-width: 80px

                                    .adjuntar
                                        border: 1px solid rgb(207, 202, 202)
                                        border-radius: 6px
                                        height: 130px
                            h3
                                margin-top: 2px
                                margin-bottom: 10px
                                font-size: 12px
                                color: rgb(207, 202, 202)
                            button
                                width: 40px
                                height: 35px
                                border-radius: 50px
                                padding: 6px
                                font-size: 15px
                                border: none
                                background-color: transparent
                                color: transparent
                                font-weight: 600
                                cursor: pointer
                                order: 2
                                margin: 2px 0 !important
                                content: ""
                                background-image: url(/img/cloud.png)
                                background-repeat: no-repeat
                                filter: opacity(0.3)
                                &:hover
                                    background-color: transparent
                                    background-image: url(/img/cloud.png)
                                    background-repeat: no-repeat
                            .fileContainer
                                padding: 5px !important
                                box-shadow: none !important
                                .uploadPictureContainer
                                    width: 60px !important
                                    margin: 10px !important
                                    padding: 5px !important
                                    img.uploadPicture
                                        max-width: 50px !important
                                p
                                    display: none !important
                                .errorsContainer
                                    max-width: 100% !important
                                input
                                    order: 1
                            .deleteImage
                                background: #fff !important
                                box-shadow: 0 0 5px #939393
                                font-size: 20px !important
                                font-weight: 400 !important
                                color: #000 !important
                    .boxBtnContinuarFotos
                        width: 50%
                        margin-bottom: 20px
                        button
                            +mediaMax780
                                margin-top: 20px
                    .listFotografias
                        text-align: left
                .contenedorListadoAD
                    margin-top: 10px
                    width: 100%
                    +mediaMax780
                        margin-top: 30px
                    a
                        text-decoration: none
                    li
                        list-style: none
                        padding: 20px 20px
                        box-sizing: border-box
                        text-align: left
                        position: relative
                        cursor: pointer
                        overflow: hidden
                        margin-bottom: 10px
                        margin-top: -1px
                        font-size: 16px
                        color: #4a4a4a
                        background: white
                        text-decoration: none
                        border-bottom: 1px solid #e8e8e8
                        &:first-child
                            margin-top: 20px
                            border-bottom: 1px solid #e8e8e8
                            border-top: 1px solid #e8e8e8
                        .is-dark-mode &
                            background-color: #292929
                            color: white
                        &::after
                            content: ''
                            position: absolute
                            right: 20px
                            top: 50%
                            margin-top: -6px
                            width: 10px
                            height: 10px
                            display: block
                            border-top: 2px solid
                            border-right: 2px solid
                            transform: rotate(45deg)
                            color: #4a4a4a
                            .is-dark-mode &
                                color: white
                .boxIndicacionesAD
                    display: block
                    width: 70%
                    margin-left: auto
                    margin-right: auto
                    margin-top: 20px
                    +mediaMax780
                        width: 90%
                        margin-top: 60px
                    .indicacionesBoxinterno
                        margin-top: 40px
                        .parrafo1
                            margin-top: 35px
                            text-align: center
                            line-height: 22px
                        ul.normasL1
                            list-style: disc
                            margin-left: 20px
                            li
                                padding: 8px
                        .normasL2
                            list-style: disc
                            margin-left: 15px
                            margin-top: 5px

                    .boxBtn
                        width: 70%
                        margin-left: auto
                        margin-right: auto
                        margin-top: 70px
                        +mediaMax780
                            width: 100%
                        .btnContinuar
                            cursor: pointer
                            width: 100%
                            box-sizing: border-box
                            background: linear-gradient(0deg, #e4002b, #f30056)
                            height: 40px
                            display: block
                            float: left
                            border: none
                            color: white
                            border-radius: 16px
                            margin-left: 10px
                            +mediaMax780
                                margin-left: 0
                .boxMotivoCancelacionAD
                    display: block
                    width: 40%
                    margin-left: auto
                    margin-right: auto
                    margin-top: 20px
                    +mediaMax780
                        width: 90%
                        margin-top: 60px
                    .inputMotivo
                        width: 100%
                        border-top: none
                        border-right: none
                        border-left: none
                        margin-bottom: 5px
                        outline: none
                        &::placeholder
                            color: #666666
                            font-size: 13px
                            font-family: Arial, Helvetica, sans-serif

                    .inputMotivo2
                        width: 100%
                        border-top: none
                        border-right: none
                        border-left: none
                        margin-bottom: 5px
                        outline: none
                        height: 20px
                        -webkit-box-sizing: border-box
                        -moz-box-sizing: border-box
                        box-sizing: border-box
                    .contadorCaracteres
                        text-align: right
                        font-size: 12px
                        color: gray
                    .boxBtn
                        width: 70%
                        margin-left: auto
                        margin-right: auto
                        margin-top: 70px
                        +mediaMax780
                            width: 100%
                        .btnContinuar
                            cursor: pointer
                            width: 100%
                            box-sizing: border-box
                            background: linear-gradient(0deg, #e4002b, #f30056)
                            height: 40px
                            display: block
                            float: left
                            border: none
                            color: white
                            border-radius: 16px
                            margin-left: 10px
                            +mediaMax780
                                margin-left: 0
                            &:disabled
                                background: rgb(155, 155, 155)

.boxUlRazonesAD
    margin-top: 10px
    width: 100%
    ul
        li
            display: flex
            align-content: center
            border-bottom: none
            align-items: center
            padding: 20px
            min-height: 20px
            box-sizing: border-box
            cursor: pointer
            border-bottom: 1px solid #e8e8e8
            &:first-child
                border-top: 1px solid #e8e8e8
                margin-top: 40px
            .is-dark-mode &
                border-bottom: 1px solid #484848
            .nombreRazon
                text-align: left
                width: 98%
            .iconoRazon
                &::after
                    content: ''
                    right: 20px
                    width: 10px
                    height: 10px
                    display: block
                    border-top: 1px solid #747474
                    border-right: 1px solid #747474
                    transform: rotate(45deg)
        .arrowBottom
            .iconoRazon
                &::after
                    content: ''
                    right: 20px
                    width: 10px
                    height: 10px
                    display: block
                    border-top: 1px solid #747474
                    border-right: 1px solid #747474
                    transform: rotate(135deg) !important
    .boxMotivoCancelacionVista12
        width: 94%
        margin: 20px auto
        .inputMotivo
            width: 90%
            border-top: none
            border-right: none
            border-left: none
            margin-bottom: 5px
            outline: none
        textarea
            border-bottom: 2px solid #cfcaca
            &::placeholder
                color: #959393
    .boxBtn
        max-width: 350px
        display: flex
        justify-content: center
        margin: 40px auto 40px auto
        .btnContinuar
            cursor: pointer
            width: 100%
            box-sizing: border-box
            background: linear-gradient(0deg, #e4002b, #f30056)
            height: 40px
            display: block
            float: left
            border: none
            color: white
            border-radius: 16px
            margin-left: 10px
            &:disabled
                background: #9b9b9b
.sinMarginTop
    margin-top: 0 !important
    +mediaMax780
        margin-top: 40px
.mensajeError
    color: red

.bxNoTerminoDesk
    width: 80%
    margin: 50px auto 20px auto
    .bxBtnNotermino
        width: 80%
        margin: 60px auto 30px auto
        .btnNoTermino
            cursor: pointer
            width: 100%
            box-sizing: border-box
            background: linear-gradient(0deg, #e4002b, #f30056)
            height: 40px
            display: block
            float: left
            border: none
            color: white
            border-radius: 16px
            margin-left: 10px
