@import '../../../../sass/_abstracts'
#root
    overflow-x: hidden
.contentModal
    .is-dark-mode &
        background: #191919 !important
    .contentClose
        box-shadow: -2px 3px 10px rgba(0, 0, 0, 0.15)
        .logoSeccion
            width: calc( 100% - 25px )
            img
                width: 120px
        .closeXAD
            box-sizing: border-box
            right: 0px
            position: absolute
            cursor: pointer
            font-weight: 400
            width: 25px
            color: $blanco
            padding: 15px
            height: 25px
            text-align: center
            display: flex
            margin-right: 5px
            background: linear-gradient(0deg, #e4002b, #f30056)
            border-radius: 50%
            &:before
                font-size: 20px
                content: '\2716'
.headerImagenesTitulo
    display: flex
    align-items: center
    justify-content: center
    border-bottom: 1px solid #e3e3e3
    padding-bottom: 20px
    .tituloImagenes
        margin-left: 10px
        font-weight: 500
        text-align: left
        font-size: 16px
        margin-top: 0
.contenedorImagenesIgual1
    // padding-bottom: 20px
    // border-bottom: 1px solid #e3e3e3
    width: 110px
    // margin: 20px auto 30px auto
    .desktopView &
        border-bottom: none
    ul.ulIgual1
        display: flex
        margin-left: auto
        margin-right: auto
        align-items: center
        justify-content: center
        border-radius: 50%
        border: 1px solid #d9d9d9
        max-width: 100px
        height: 100px
        overflow: hidden
        li
            padding: 0
            margin: 0px
            width: 100px
            height: 100px
            img
                +mediaMax480
                    width: 100px !important
                    height: 100px

.contenedorImagenesMas1
    // padding-bottom: 20px
    // border-bottom: 1px solid #e3e3e3
    width: 110px
    // margin: 20px auto 30px auto
    .desktopView &
        border-bottom: none
    ul.ulMas1
        display: grid
        grid-template-columns: repeat(2, 1fr)
        grid-template-rows: repeat(2, 1fr)
        margin-left: auto
        margin-right: auto
        align-items: center
        justify-content: center
        border-radius: 50%
        border: 1px solid #d9d9d9
        max-width: 100px
        height: 100px
        overflow: hidden
        li
            padding: 0
            margin: 0px
            width: 50px
            height: 50px
            img
                +mediaMax480
                    width: 50px !important
                    height: 50px
        li.mas1
            padding: 0px
            margin-left: 5px
            display: flex
            align-items: center
.contenedorListado
    margin-top: 50px
    a
        text-decoration: none
    li
        list-style: none
        padding: 10px 20px
        box-sizing: border-box
        border-top: 1px solid var(--color-gris-borde)
        text-align: left
        position: relative
        cursor: pointer
        border: 1px solid var(--color-gris-borde)
        border-radius: 0 0 3px 3px
        overflow: hidden
        margin-bottom: 10px
        margin-top: -1px
        font-size: 16px
        color: #4a4a4a
        background: white
        font-weight: 600
        text-decoration: none
        .is-dark-mode &
            background-color: #292929
            color: white
            border: none
        &::after
            content: ''
            position: absolute
            right: 20px
            top: 50%
            margin-top: -6px
            width: 10px
            height: 10px
            display: block
            border-top: 2px solid
            border-right: 2px solid
            transform: rotate(45deg)
            color: #4a4a4a
            .is-dark-mode &
                color: white
.copyEspera
    background-color: #eeeeee
    padding: 15px
    margin-top: 50px
    .is-dark-mode &
        background-color: #292929
    p
        font-weight: 500
.boxUlRazones
    margin-top: 30px
    width: 100%
    ul
        border-left: 1px solid #e3e3e3
        border-right: 1px solid #e3e3e3
        border-radius: 8px
        .desktopView &
            border-top: 0px solid #e3e3e3
            border-left: none
            border-right: none
            border-radius: 0
        li
            display: flex
            align-content: center
            border-bottom: none
            align-items: center
            padding: 20px 10px
            min-height: 20px
            box-sizing: border-box
            cursor: pointer
            border-bottom: 1px solid #e3e3e3
            &:last-child
                .desktopView &
                    border-bottom: 1px solid #e3e3e3
            &:first-child
                border-top: 1px solid #e3e3e3 
            .nombreRazon
                text-align: left
                width: 98%
            .iconoRazon
                &::after
                    content: ''
                    right: 20px
                    width: 8px
                    height: 8px
                    display: block
                    border-top: 2px solid #747474
                    border-right: 2px solid #747474
                    transform: rotate(45deg)
        .arrowBottom
            .iconoRazon
                &::after
                    content: ''
                    right: 20px
                    width: 8px
                    height: 8px
                    display: block
                    border-top: 2px solid #747474
                    border-right: 2px solid #747474
                    transform: rotate(135deg) !important
    .boxMotivoCancelacionVista12
        width: 94%
        margin: 20px auto
        .inputMotivo
            width: 100%
            border-top: none
            border-right: none
            border-left: none
            margin-bottom: 5px
            outline: none
        textarea
            border-bottom: 2px solid #cfcaca
            &::placeholder
                color: #959393
    .boxBtn
        max-width: 350px
        display: flex
        justify-content: center
        margin: 20px auto
        .btnContinuar
            cursor: pointer
            width: 100%
            box-sizing: border-box
            background: linear-gradient(0deg, #e4002b, #f30056)
            height: 40px
            display: block
            float: left
            border: none
            color: white
            border-radius: 16px
            margin-left: 10px
            &:disabled
                background: #9b9b9b
.boxMotivoCancelacion
    display: block
    width: 25%
    margin-left: auto
    margin-right: auto
    margin-top: 20px
    +mediaMax780
        width: 90%
    .inputMotivo
        width: 100%
        border-top: none
        border-right: none
        border-left: none
        margin-bottom: 5px
        outline: none
        &::placeholder
            color: #666666
            font-size: 13px
            font-family: Arial, Helvetica, sans-serif

    .inputMotivo2
        width: 100%
        border-top: none
        border-right: none
        border-left: none
        margin-bottom: 5px
        outline: none
        height: 20px
        -webkit-box-sizing: border-box
        -moz-box-sizing: border-box
        box-sizing: border-box
    .contadorCaracteres
        text-align: right
        font-size: 12px
        color: gray
    .boxBtn
        width: 70%
        margin-left: auto
        margin-right: auto
        margin-top: 20px
        +mediaMax780
            width: 100%
        .btnContinuar
            cursor: pointer
            width: 100%
            box-sizing: border-box
            background: linear-gradient(0deg, #e4002b, #f30056)
            height: 40px
            display: block
            float: left
            border: none
            color: white
            border-radius: 16px
            margin-left: 10px
            +mediaMax780
                margin-left: 0
            &:disabled
                background: rgb(155, 155, 155)

.modalRemoveAddress
    .boxTextos
        h3
            margin-bottom: 15px
            font-weight: 600
        p
            font-weight: 400
            color: #707070dd
    .contBtns
        .btnContinuar
            cursor: pointer
            width: 100%
            box-sizing: border-box
            background: linear-gradient(0deg, #e4002b, #f30056)
            height: 40px
            border: none
            color: white
            border-radius: 16px
            text-align: center
            align-items: center
            display: flex
            justify-content: center
    .contBtnsEnterado
        margin-top: 20px
        .btnContinuar
            cursor: pointer
            width: 50%
            box-sizing: border-box
            background: white
            height: 40px
            border: 1px solid #ccc
            color: black
            border-radius: 16px
            text-align: center
            align-items: center
            display: flex
            justify-content: center
            float: right

.boxCancelacionExitosa
    .boxContenido
        margin-top: 30px
        width: 100%
        margin-left: auto
        margin-right: auto
        img
            width: 65px
        h3
            margin-top: 20px
            font-size: 20px
        .textFp
            padding-top: 40px
            padding-bottom: 40px
            width: 80%
            margin-left: auto
            margin-right: auto

    .boxBtnRegresar
        margin-top: 40px
        width: 30%
        margin-right: auto
        margin-left: auto
        +mediaMax780
            width: 60%
        +mediaMax480
            width: 100%
        .btnRegresar
            cursor: pointer
            width: 100%
            box-sizing: border-box
            background: linear-gradient(0deg, #e4002b, #f30056)
            height: 40px
            border: none
            color: white
            border-radius: 16px
            text-align: center
            align-items: center
            display: flex
            justify-content: center

.boxIndicaciones
    .indicacionesBoxinterno
        h3
            text-align: center
            margin-top: 60px
            font-size: 22px
        .parrafo1
            margin-top: 55px
            text-align: center
            line-height: 22px
        ul.normasL1
            list-style: disc
            margin-left: 20px
            li
                padding: 8px
        .normasL2
            list-style: disc
            margin-left: 15px
            margin-top: 5px
    .boxBtn
        width: 70%
        margin-left: auto
        margin-right: auto
        margin-top: 80px
        margin-bottom: 30px
        +mediaMax780
            width: 90%
        .btnContinuar
            cursor: pointer
            width: 100%
            box-sizing: border-box
            background: linear-gradient(0deg, #e4002b, #f30056)
            height: 40px
            display: block
            float: left
            border: none
            color: white
            border-radius: 16px
        

.copyForm
    margin-top: 20px
    line-height: 18px
    .is-dark-mode &
        background-color: #1a1a1a
    p
        font-weight: 500
.formInformacionBancaria
    margin-top: 30px
    width: 50%
    margin-left: auto
    margin-right: auto
    +bx
    background: white
    display: inline-grid
    .is-dark-mode &
        background-color: #1a1a1a
    +mediaMax780
        width: 70%
    +mediaMax580
        width: 90%
    .copyHelp
        text-align: left
        position: relative
        color: #acacac
    .fileset2, .fileset
        width: 100%
        height: 58px
        position: relative
        padding: 10px 0
        box-sizing: border-box
        margin-bottom: 10px
        margin-top: 10px
        .is-dark-mode &     
            background-color: #1a1a1a
            ::placeholder 
                color: #e1e1e1
    .deskInput
        .is-dark-mode &
            background-color: #1a1a1a
    input
        box-sizing: border-box
        width: 100%
        padding: 10px
        font-size: 16px
        border-bottom: 1px solid var(--color-gris-borde)
        border-top: none
        border-left: none
        border-right: none
        outline: none
        .is-dark-mode &
            background-color: var(--color-background-dm)
            border: 1px solid #eee
            border-radius: 5px
 
    .btnFinalizarForm
        margin-top: 50px
        cursor: pointer
        width: 100%
        box-sizing: border-box
        background: linear-gradient(0deg, #e4002b, #f30056)
        height: 40px
        display: block
        float: left
        border: none
        color: white
        border-radius: 16px
        margin-left: 10px
        +mediaMax780
            margin-left: 0

.form-js-label
    input
        &[data-empty="false"],
        &:focus
            padding: 22px 8px 10px

        &:focus
            outline: 0
            background: rgba(255,255,255,1)

        &[data-empty="false"] + label,
        &:focus + label
            color: #979797
            font-weight: 700
            font-size: 12px
            transform: translate3d(0, -10px, 0)
    select
        &[data-empty="false"],
        &:focus
            padding: 22px 8px 10px

        &:focus
            outline: 0
        &[data-empty="false"] + label,
        &:focus + label
            color: #979797
            font-weight: 700
            font-size: 12px
            transform: translate3d(0, -10px, 0)

.fieldset2
    width: 100%
    height: 58px
    position: relative
    padding: 10px 0
    box-sizing: border-box
    margin-bottom: 10px
    label
        position: absolute
        left: 10px
        top: 20px
        font-size: 16px
        transition: all 0.2s ease-out
        pointer-events: none
        color: #999
        .is-dark-mode &
            color: var(--color-gris-borde)
    input[type="text"],
    input[type="tel"],
    input[type="email"]
        font-size: 16px
        &:focus,
        &:active,
        &:not(:placeholder-shown)
            + label
                top: 0
                font-size: 12px
                transition: all 0.2s ease-out
        .is-dark-mode &
            background: transparent
            transition: all 0.2s ease-out
    .error
        font-size: 11px
        border-top: 1px solid var(--color-rojo-pale)
        width: 100%
        padding: 0px 10px!important
        position: absolute
        bottom: -2px
        margin-bottom: 0!important

.boxSolicitarDevolucion
    margin-left: auto
    margin-right: auto
    width: 90%
    color: #4a4a4a
    .ulEntregado
        list-style: disc
        text-align: left
        margin-bottom: 40px
        margin-left: 15px
        li
            padding: 5px 0
    .devolucionp1
        margin-top: 20px
        margin-bottom: 20px
    .devolucionp2
        margin-bottom: 30px
    h3
        font-weight: 600
        text-align: center
        margin-bottom: 15px
        margin-top: 15px
    .normasL1
        list-style: disc
        margin-left: 20px
        li
            padding: 8px
            .normasL2
                list-style: disc
                margin-left: 15px
                margin-top: 5px

    .nota
        margin-top: 10px
        margin-bottom: 10px
    .boxPoliticas
        margin-top: 15px
        width: 50%
        margin-left: auto
        margin-right: auto
        text-align: center
        +mediaMax780
            width: 90%
        .linkPoliticas
            color: #4f4f4f
            .is-dark-mode &
                color: #aaaaaa
    .boxBtnContinuarDevolucion
        width: 100%
        margin-left: auto
        margin-right: auto
        margin-top: 20px
        .desktopView &
            width: 50%
            +mediaMax780
                width: 100%
        .btnContinuarDevolucion
            cursor: pointer
            width: 100%
            box-sizing: border-box
            background: linear-gradient(0deg, #e4002b, #f30056)
            height: 40px
            display: block
            float: left
            border: none
            color: white
            border-radius: 16px
            margin-left: 10px
            margin-bottom: 20px
            +mediaMax780
                margin-left: 0

.boxGeneralAdjuntar
    width: 50%
    margin-left: auto
    margin-right: auto
    +mediaMax780
        width: 80%
    +mediaMax580
        width: 90%
    .boxAdjuntarFotografiasTexto
        width: 90%
        margin-left: auto
        margin-right: auto
        +mediaMax780
            margin-top: 20px
        .listFotografias
            list-style: disc
            li
                padding: 8px
    .copy1
        margin-top: 15px
        margin-bottom: 10px
        text-align: center
    .copy2
        margin-bottom: 15px
        text-align: center
    .copyGris
        color: #aaa
    .boxFotos
        width: 100%
        margin: 30px auto
        text-align: center
        .tituloporfoto
            text-align: left
            font-weight: 500
            margin-top: 40px
        .boxInternoPorFoto
            display: flex
            align-items: center
            margin-top: 10px
            .referencia
                width: 50%
                display: flex
                justify-content: center
                img
                    max-width: 80px
            .carga
                width: 50%
                border: 1px solid rgb(207, 202, 202)
                border-radius: 6px
        button
            width: 40px
            height: 35px
            border-radius: 50px
            padding: 6px
            font-size: 15px
            border: none
            background-color: transparent
            color: transparent
            font-weight: 600
            cursor: pointer
            order: 2
            margin: 2px 0 !important
            content: ""
            background-image: url(/img/cloud.png)
            background-repeat: no-repeat
            filter: opacity(0.3)
            &:hover
                background-color: transparent
                background-image: url(/img/cloud.png)
                background-repeat: no-repeat
        h3
            margin-top: 2px
            margin-bottom: 10px
            font-size: 12px
            color: rgb(207, 202, 202)
        .fileContainer
            padding: 0 !important
            box-shadow: none !important
            .is-dark-mode &
                background-color: #222
            .uploadPictureContainer
                width: 60px !important
                margin: 10px !important
                padding: 5px !important
                img.uploadPicture
                    max-width: 50px !important

            p
                display: none !important
            .errorsContainer
                max-width: 100% !important
            input
                order: 1
        .deleteImage
            background: #fff !important
            box-shadow: 0 0 5px #939393
            font-size: 20px !important
            font-weight: 400 !important
            color: #000 !important
    .boxBtnContinuarFotos
        width: 100%
        margin-left: auto
        margin-right: auto
        margin-top: 40px
        +mediaMax780
            width: 100%
        .btnContinuarFotos
            cursor: pointer
            width: 100%
            box-sizing: border-box
            background: linear-gradient(0deg, #e4002b, #f30056)
            height: 40px
            display: block
            float: left
            border: none
            color: white
            border-radius: 16px
            margin-left: 10px
            margin-bottom: 30px
            +mediaMax780
                margin-left: 0
            &:disabled
                background: #9b9b9b
                cursor: auto
.noneButton, .swal-button, .swal-button-container, .swal-footer
    display: none
.swal-text:first-child
    margin: 12px !important
.swal-overlay--show-modal
    opacity: 0 !important
.swal-overlay
    position: fixed
    top: auto !important
    bottom: 0
    left: 0
    right: 0
    text-align: center
    font-size: 0
    overflow-y: auto
    background-color: rgba(0, 0, 0, 0) !important
    z-index: 10000
    pointer-events: none
    opacity: initial !important
    transition: opacity .3s
.swal-modal
    background-color: #000 !important
    .swal-text
        color: white !important
.gm-style-iw-tc
    display: none !important