@import '../../../sass/_abstracts'

.react-tabs__tab-list
    border: none
    .is-dark-mode &
        margin-top: 20px
#alineacion2
    text-align: left
    // display: grid
    // grid-template-columns: repeat(2, 1fr)
    .react-tabs__tab
        text-align: center
        width: 30% !important
#alineacion3
    // text-align: left
    // display: grid
    // grid-template-columns: repeat(3, 1fr)
    .react-tabs__tab
        text-align: center
        width: 30% !important
#alineacion4
    text-align: left
    // display: grid
    // grid-template-columns: repeat(4, 1fr)
    .react-tabs__tab
        text-align: center
        width: 20% !important
.react-tabs
    width: 80%
    margin-left: auto
    margin-right: auto
    padding-top: 0
    padding-left: 0
    padding-right: 0
    padding-bottom: 20px
    margin-bottom: 20px
    +mediaMax960
        width: 95%
    +mediaMax780
        width: 90%

.react-tabs__tab-panel
    margin-bottom: 20px
.react-tabs__tab
    font-weight: 700 !important
    color: #7a7777
    border-bottom: 4px solid #e8e8e8
    border-top: none
    border-right: none
    border-left: none
    outline: none
    .is-dark-mode &
        color: white
        border-bottom: 4px solid #2d2d2d
.react-tabs__tab--selected
    color: black
    border-bottom: 4px solid red
    border-top: none
    border-right: none
    border-left: none
    outline: none
    .is-dark-mode &
        background: red
        color: white
        border-bottom: 4px solid white
.moduleDescriptionSears
    width: 100%
    padding: 20px
    box-sizing: border-box
    text-align: left
    background-color: white
    position: relative
    .is-dark-mode &
        background: var(--color-background-dm)
        color: white
        border-color: #666
    .h3
        display: flex
        flex-direction: column
        align-items: flex-start
        font-size: 16px
        font-weight: 500
        margin-bottom: 10px
.textDescriptionSears
    white-space: pre-wrap
    font-size: 14px
    line-height: 1.5
    text-overflow: ellipsis
    overflow: hidden
    -webkit-line-clamp: 7
    -webkit-box-orient: vertical
    display: -webkit-box
    .desktopView &
        max-height: 100%
        overflow: inherit
        display: block
    p > img
        width: 100%
    > div > table,
    > table
        width: 100%!important
        margin: 0!important
        tbody
            @media only screen and (max-width: 780px)
                display: flex
                flex-direction: column
                box-sizing: border-box
                width: calc(100vw - 90px)
        strong
            display: block
            width: 100%  
        tr
            @media only screen and (max-width: 780px)
                display: flex
                flex-direction: column
                width: 100%!important
                height: auto
                box-sizing: border-box
            td
                width: 100%!important
                box-sizing: border-box
                white-space: initial!important
                @media only screen and (max-width: 780px)
                    max-width: 100%!important
                    height: auto!important
                    height: auto!important
                &:first-child
                    max-width: 40%
                    font-weight: 600
                    strong
                        &:after
                            content: " : "
                    @media only screen and (max-width: 780px)
                        max-width: 100%
                &:last-child
                    max-width: 600%
                    @media only screen and (max-width: 780px)
                        max-width: 100%
                        background: #EFEFEF

#descripcion
    > div > table,
    > table
        width: 100%!important
        margin: 0!important
        tbody
            @media only screen and (max-width: 780px)
                display: flex
                flex-direction: column
                box-sizing: border-box
                width: calc(100vw - 40px)
        strong
            display: block
            width: 100%  
        tr
            @media only screen and (max-width: 780px)
                display: flex
                flex-direction: column
                width: 100%!important
                height: auto
                box-sizing: border-box
            td
                width: 100%!important
                box-sizing: border-box
                white-space: initial!important
                @media only screen and (max-width: 780px)
                    max-width: 100%!important
                    height: auto!important
                    height: auto!important
                &:first-child
                    max-width: 40%
                    font-weight: 600
                    strong
                        &:after
                            content: " : "
                    @media only screen and (max-width: 780px)
                        max-width: 100%
                &:last-child
                    max-width: 600%
                    @media only screen and (max-width: 780px)
                        max-width: 100%
                        background: #EFEFEF
    p
        > img
            width: 100%
            margin-bottom: 10px

.moduleEspecificacionSears
    width: 100%
    padding: 20px
    box-sizing: border-box
    border-bottom: 1px solid var(--color-gris-borde)
    text-align: left
    background-color: white
    position: relative
    color: inherit
    text-decoration: none
    display: block
    .is-dark-mode &
        background: var(--color-background-dm)
        color: white
        border-color: #666
    .h3
        display: flex
        flex-direction: column
        align-items: flex-start
        font-size: 16px
        font-weight: 500
        margin-bottom: 10px
    .modulePartEspecificacionSears
        cursor: pointer
    .moduleLinkEspecificacion
        color: inherit
        text-decoration: none
        display: block

.pagosprincipal
    display: flex
    width: 100%
    .unoPagos
        width: 50%
        text-align: left
        h3
            font-weight: 600
            margin-bottom: 30px
            margin-top: 10px
    .dosPagos
        width: 50%
        text-align: left
        h3
            font-weight: 600
            margin-bottom: 30px
            margin-top: 10px
        .uldospagos
            li
                list-style: none
                margin-bottom: 8px
                color: #4a4a4a
.listAttributesSears
    display: flex
    flex-direction: row
    flex-wrap: wrap
    li
        box-sizing: border-box
        padding: 5px 0px
        display: inline
        font-size: 14px
        width: 100%
        color: #414141
        .atributesKeySears
            padding-bottom: 5px
            font-weight: 600
            text-transform: capitalize
            .is-dark-mode &
                color: white
            &:after
                content: ': '
        .atributesValueSears
            padding-left: 0
            font-weight: 400
            .is-dark-mode &
                color: var(--color-gris-borde)

.moduleFormaPagoSears
    display: block
    p
        color: #4a4a4a
        text-align: left
        padding-left: 17px
        padding-bottom: 20px
        padding-top: 10px
        span
            font-weight: 600
            color: #000
    .moduloUl
        display: flex
        +mediaMax780
            display: block
        .is-dark-mode &
            background: var(--color-background-dm)
            padding: 15px 20px
        .ulFp1
            width: 50%
            +mediaMax780
                width: 100%
            .liFp
                display: flex
                align-items: center
                padding: 20px
                .bxImg
                    width: 60px//15%
                    text-align: left
                    +mediaMax480
                        margin-right: 10px
                    img
                        width: 60px
                .bxName
                    width: 85%
                    text-align: left
                    +mediaMax480
                        width: calc( 100% - 70px )
                    .is-dark-mode &
                        color: #ededed
    .ulFp2
        width: 50%
        +mediaMax480
            width: 100%
        .liFp
            display: flex
            align-items: center
            padding: 20px
            .bxImg
                width: 60px//15%
                margin-right: 10px
                text-align: left
                img
                    width: 60px
            .bxName
                width: calc( 100% - 70px )//85%
                text-align: left
                .is-dark-mode &
                    color: #ededed

.moduleWarrantySears
    .is-dark-mode &
        background: var(--color-background-dm)
        padding: 15px 20px
    .warrantyLSears,
    .warrantyRSears
        text-align: left
        display: flex
        align-items: center
        .titulo
            // font-weight: 600
            // // font-size: 20px
            // margin-bottom: 10px
            // display: flex
            // justify-content: left
            // .is-dark-mode &
            //     color: #d0d0d0
            &::before
                content: ''
                background-size: contain
                background-repeat: no-repeat
                background-position: center center
                width: 40px
                height: 40px
                background-image: url(/img/garantiaRed.png)
                display: block
                // padding-left: 10px
        ul.opciones
            text-align: left
            width: 96%
            margin-left: auto
            margin-right: auto
            .is-dark-mode &
                color: #d0d0d0
            li
                padding-top: 8px
                padding-left: 10px
                list-style: none
                color: #4a4a4a
                // span
                //     font-weight: 600
    .condicionesSears
        .titulo
            font-weight: 600
            margin-top: 20px
            margin-bottom: 10px
            text-align: left
            .is-dark-mode &
                color: #d0d0d0
        ul.ulCondiciones
            width: 96%
            margin-left: auto
            margin-right: auto
            text-align: left
            .is-dark-mode &
                color: #d0d0d0
            li
                list-style: disc
                padding: 5px
                text-align: left
                color: #4a4a4a
        .pEnlaceDevoluciones
            display: flex
            align-items: center
            margin-top: 20px
            margin-bottom: 20px
            color: #4a4a4a
            a
                margin-left: 7px
                // text-align: left
                // float: left
                color: #4c96f7
                // margin-top: 20px
                // margin-bottom: 20px

.tabMobile
    margin-bottom: 10px
    width: 100%
    .secPromociones
        width: 100%
        padding: 10px 0px
        box-sizing: border-box
        background-color: white
        position: relative
        p
            display: flex
            flex-direction: row
            align-items: center
            cursor: pointer
            color: inherit
            width: 96%
            margin-left: auto
            font-weight: 600
            &::after
                content: ''
                position: absolute
                right: 10px
                width: 10px
                height: 10px
                display: block
                border-top: 2px solid
                border-right: 2px solid
                transform: rotate(135deg)
                color: #979797
    .react-tabs__tab
        color: #c5c5c5 !important
    .react-tabs__tab--selected
        color: #000 !important
    .react-tabs
        width: 98% !important
        margin-left: auto
        margin-right: auto
    .react-tabs__tab
        width: 40%
    .ulTarjetasSearsMobile,
    .ulpagosMobile
        list-style: disc
        text-align: left
        width: 85%
        margin-left: auto
        margin-right: auto
    .ulpagosMobile
        li
            padding: 10px 0
    .seccionTarjetaBancaria,
    .seccionTarjetaSears
        padding-top: 10px

.intoPromo
    padding: 5px 20px
    box-sizing: border-box

.ulTarjetasSears
    > li
        padding-bottom: 10px
        margin-bottom: 10px
        border-bottom: 1px solid #ddd
        font-weight: 600
        &:last-child
            border-bottom: none
        ul
            padding-left: 30px
            list-style: disc
            >li
                font-weight: 400
                line-height: 1.4

.ulModalPromoSears
    > li
        padding-bottom: 10px
        margin-bottom: 10px
        border-bottom: 1px solid #ddd
        font-weight: 600
        &:last-child
            border-bottom: none