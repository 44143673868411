@import '../../../../sass/_abstracts'
.detallePedido
    padding: 0px 0px
    margin-top: 10px
    width: calc( 100% - 350px )
    margin-left: auto
    margin-right: auto
    border: 1px solid rgba(0,0,0,0.1)
    border-radius: 5px
    +mediaMax780
        width: 100%
        margin-bottom: 20px
    .dataModal
        +bx
        // padding: 20px 25px
        margin-bottom: 10px
        text-align: left
        .headOrder
            box-sizing: border-box
            // padding: 20px 5px
            // width: calc(100% - 50px)
            // margin: -35px 5px 10px 5px
            // background: white
            // position: absolute
        .bodyOrderDetail
            box-sizing: border-box
            .is-dark-mode &
                background-color: var(--color-background-dm)
    .contentModal
        transform: initial
        top: 0
        left: 0
        border-radius: 0
        background: #fff
        border-radius: 5px
        padding: 20px 0px
        height: auto
        overflow-y: auto
        overflow-x: hidden
        .is-dark-mode &
            background: #292f35
            color: white
        .contentClose
            display: flex
            flex-direction: row
            align-items: center
            padding: 20px 25px
            margin: -20px -14px 0
            box-sizing: border-box
            border-bottom: 1px solid var(--color-gris-borde)
            position: sticky
            top: -20px
            background: white
            z-index: 1
            justify-content: center
            .is-dark-mode &
                background-color: var(--color-background-dm)
                position: sticky !important
            .title
                @media only screen and ( max-width:  360px)
                    font-size: 13px
            .btn
                box-sizing: border-box
                display: flex
                flex-direction: row
                align-items: center
                justify-content: center
                border-radius: 5px
                margin-bottom: 10px
                font-size: 14px
                font-weight: 700
                text-decoration: none
                padding: 8px 16px
                cursor: pointer
                width: 100%
                height: 42px
                -webkit-user-select: none
                user-select: none

                .is-dark-mode &
                    color: white
                    filter: invert(1)

            .btn.close
                // position: relative
                display: inline
                // top: 3px
                float: left
                left: 30px
                position: absolute
                right: inherit
                margin-bottom: 0
                margin-right: 20px
                background-color: #e4002b
                color: black
                transform: rotate(180deg)
                cursor: pointer
                font-weight: 500
                width: 28px
                padding: 5px
                height: 28px
                text-align: center
                line-height: 0.9
                border-radius: 50%
                .is-dark-mode &
                    background-color: transparent
                    color: white
                &:before
                    content: '\2794'
                    font-size: 20px
                    position: relative
                    color: white
                    display: flex
                    align-items: center
                    justify-content: center
                    // top: -2px
                    .is-dark-mode &
                        color: white
                        filter: invert(1)

.modalDetallePedidoMobile
    padding: 0px 0px
    width: 100%
    margin-left: auto
    margin-right: auto
    border-radius: 5px
    .dataModalMobile
        +bx
        margin-bottom: 10px
        text-align: left
        margin-top: 10px
        border-top: 1px solid rgba(0,0,0,0.1)
        .headOrder
            box-sizing: border-box
        .bodyOrderDetail
            box-sizing: border-box
            .is-dark-mode &
                background-color: var(--color-background-dm)
    .contentModal
        transform: initial
        top: 0
        left: 0
        border-radius: 0
        background: #fff
        border-radius: 5px
        padding: 20px 0px
        height: auto
        overflow-y: auto
        overflow-x: hidden
        .is-dark-mode &
            background: #292f35
            color: white
        .contentClose
            display: flex
            flex-direction: row
            align-items: center
            padding: 20px 25px
            margin: -20px -14px 0
            box-sizing: border-box
            box-shadow: 0 4px 2px -2px rgba(0,0,0,0.1)
            position: sticky
            top: -20px
            background: white
            z-index: 1
            justify-content: center
            .is-dark-mode &
                background-color: var(--color-background-dm)
                position: sticky !important
            .title
                @media only screen and ( max-width:  360px)
                    font-size: 13px
            .btn
                box-sizing: border-box
                display: flex
                flex-direction: row
                align-items: center
                justify-content: center
                border-radius: 5px
                margin-bottom: 10px
                font-size: 14px
                font-weight: 700
                text-decoration: none
                padding: 8px 16px
                cursor: pointer
                width: 100%
                height: 42px
                -webkit-user-select: none
                user-select: none
                .is-dark-mode &
                    color: white
                    filter: invert(1)
            .btn.close
                display: inline
                float: left
                left: 30px
                position: absolute
                right: inherit
                margin-bottom: 0
                margin-right: 20px
                background-color: #e4002b
                color: black
                transform: rotate(180deg)
                cursor: pointer
                font-weight: 500
                width: 28px
                padding: 5px
                height: 28px
                text-align: center
                line-height: 0.9
                border-radius: 50%
                .is-dark-mode &
                    background-color: transparent
                    color: white
                &:before
                    content: '\2794'
                    font-size: 20px
                    position: relative
                    color: white
                    display: flex
                    align-items: center
                    justify-content: center
                    .is-dark-mode &
                        color: white
                        filter: invert(1)
.sinblur
    backdrop-filter: none !important

.modalDetallePedido
    width: 100vw
    height: 100%
    background: rgba(0,0,0,0.8)
    backdrop-filter: blur(5px)
    z-index: 10
    overflow-y: auto
    overflow-x: hidden
    .is-dark-mode &
        width: 101vw
        height: 101vh
        background: rgba(100,100,100,0.4)
        @-moz-document url-prefix()
            background: rgba(25,25,25,0.8)
    .contentModal
        transform: initial
        top: 0
        left: 0
        border-radius: 0
        background: #fff
        padding: 20px 15px
        height: auto
        overflow-y: auto
        overflow-x: hidden
        .is-dark-mode &
            background: #292f35
            color: white
    .dataModal
        +bx
        padding: 20px 10px
        margin-bottom: 10px
        text-align: left
        .headOrder
            box-sizing: border-box
            padding: 20px
            width: calc(100% + 70px)
            margin: -35px -35px 10px -35px
            background: white
            position: absolute
        .bodyOrder
            box-sizing: border-box
            padding: 35px
            width: calc(100% - 70px)
            margin: 58px -35px -50px -35px
            background-color: #f2f2f2
            .is-dark-mode &
                background-color: var(--color-background-dm)

    .contentClose
        display: flex
        flex-direction: row
        align-items: center
        padding: 20px 20px
        margin: -20px -14px 0
        box-sizing: border-box
        width: calc(100% + 30px)
        border-bottom: 1px solid var(--color-gris-borde)
        position: sticky
        top: -20px
        background: white
        z-index: 1
        .is-dark-mode &
            background-color: var(--color-background-dm)
            position: sticky !important
        .btn
            box-sizing: border-box
            display: flex
            flex-direction: row
            align-items: center
            justify-content: center
            border-radius: 5px
            margin-bottom: 10px
            font-size: 14px
            font-weight: 700
            text-decoration: none
            padding: 8px 16px
            cursor: pointer
            width: 100%
            height: 42px
            -webkit-user-select: none
            user-select: none
            .is-dark-mode &
                color: white
                filter: invert(1)

        .btn.close
            position: relative
            display: inline
            top: 3px
            right: inherit
            margin-bottom: 0
            margin-right: 20px
            background-color: #e4002b
            color: black
            transform: rotate(180deg)
            cursor: pointer
            font-weight: 500
            width: 28px
            padding: 5px
            height: 28px
            text-align: center
            line-height: 0.9
            border-radius: 50%
            .is-dark-mode &
                background-color: transparent
                color: white
            &:before
                content: '\2794'
                font-size: 20px
                position: relative
                color: white
                display: flex
                align-items: center
                justify-content: center
                // top: -2px
                .is-dark-mode &
                    color: white
                    filter: invert(1)
    .contentCloseX
        display: flex
        flex-direction: row
        align-items: center
        padding: 20px 20px
        box-sizing: border-box
        width: calc(100% + 30px)
        border-bottom: 1px solid var(--color-gris-borde)
        position: sticky
        top: -20px
        background: white
        z-index: 1
        box-shadow: 3px 6px 10px rgba(0, 0, 0, 0.15)
        .is-dark-mode &
            background-color: var(--color-background-dm)
        .imgClaro
            width: 40%
            margin-left: auto
            margin-right: auto
            img
                width: 100%
                max-width: 130px
                min-width: 80px
                .is-dark-mode &
                    filter: invert(1) hue-rotate(220deg) saturate(1000%)
        .btn
            box-sizing: border-box
            display: flex
            flex-direction: row
            align-items: center
            justify-content: center
            border-radius: 5px
            margin-bottom: 10px
            font-size: 14px
            font-weight: 700
            text-decoration: none
            padding: 8px 16px
            cursor: pointer
            width: 100%
            height: 42px
            -webkit-user-select: none
            user-select: none
        .btn.closeX
            box-sizing: border-box
            position: absolute
            right: 45px
            cursor: pointer
            font-weight: 500
            width: 28px
            color: white
            background: var(--color-rojo-pale) //black
            border-radius: 50%
            padding: 5px
            height: 28px
            text-align: center
            line-height: 0.9
            &:before
                font-size: 20px
                content: '\2716'
                font-weight: 400
